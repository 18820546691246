<script>
import axios from 'axios'

export default {
  name: 'hiding-action',
  props: {
    smartDocumentRequestRecord: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      smartDocumentRequest: this.smartDocumentRequestRecord
    }
  },
  computed: {
    isHiding () {
      return this.smartDocumentRequest.hidden
    },
    successMessage () {
      if (this.isHiding) {
        return 'Hide successfully added'
      } else {
        return 'Hide successfully deleted'
      }
    },
    hideTooltipText () {
      if (this.isHiding) {
        return 'Unhide question for client'
      } else {
        return 'Hide question for client'
      }
    }
  },
  methods: {
    async toggleHiding () {
      try {
        const url = `/smart_document_requests/${this.smartDocumentRequest.id}`
        await axios.put(url, {
          hidden: !this.isHiding
        })
        this.smartDocumentRequest.hidden = !this.isHiding
        toastr.success(this.successMessage)
      } catch (e) {
        console.log(e)
        toastr.error('Something went wrong')
      }
    }
  }
}
</script>

<template>
  <div class="hiding-action-container">
    <i
      data-toggle="tooltip"
      class="pr-1 pl-1 cursor-pointer glyphicon eye-hide-icon"
      :data-original-title="hideTooltipText"
      @click="toggleHiding"
    >
      <span
        :class="{ 'dw-icon-cancel-eye': isHiding, 'dw-icon-eye': !isHiding, 'is-hiding': isHiding }"
      />
    </i>

    <div
      v-if="isHiding"
      class="message-container"
      style="left: 90px !important;"
    >
      <div class="left-arrow-shape" />
      <div class="right-text-content">
        This question is currently hidden from your client
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hiding-action-container {
  display: inline-block;
  font-size: 21px;
  cursor: pointer;
  color: rgb(144, 144, 144);
  opacity: 0.6;

  .is-hiding {
    color: rgb(25, 118, 210);
    opacity: 1;
  }
}
</style>
