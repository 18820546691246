<script>
import axios from 'axios'

export default {
  name: 'flag-action',
  props: {
    smartDocumentRequestRecord: {
      type: Object,
      required: true
    },
    isAttorney: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      smartDocumentRequest: this.smartDocumentRequestRecord
    }
  },
  computed: {
    isFlagged () {
      return this.smartDocumentRequest.flagged
    },
    successMessage () {
      if (this.isFlagged) {
        return 'Flag successfully added'
      } else {
        return 'Flag successfully deleted'
      }
    },
    hideTooltipText () {
      if (this.isFlagged) {
        return 'Unflag question for client'
      } else {
        return 'Flag question for client'
      }
    }
  },
  methods: {
    async toggleFlag () {
      try {
        const url = `/smart_document_requests/${this.smartDocumentRequest.id}`
        await axios.put(url, {
          flagged: !this.isFlagged
        })
        this.smartDocumentRequest.flagged = !this.isFlagged
        toastr.success(this.successMessage)
      } catch (e) {
        console.log(e)
        toastr.error('Something went wrong')
      }
    }
  }
}
</script>

<template>
  <div
    class="flag-action-container"
    :class="{ 'cursor-pointer': isAttorney }"
  >
    <i
      v-if="isAttorney"
      data-toggle="tooltip"
      class="pr-1 pl-1 glyphicon"
      :class="{ 'dw-icon-flag': !isFlagged, 'dw-icon-flag-fill': isFlagged, 'is-flagged': isFlagged }"
      :data-original-title="hideTooltipText"
      @click="toggleFlag"
    />

    <i
      v-if="!isAttorney && isFlagged"
      class="pr-1 pl-1 glyphicon"
      :class="{ 'dw-icon-flag': !isFlagged, 'dw-icon-flag-fill': isFlagged, 'is-flagged': isFlagged }"
    />
  </div>
</template>

<style lang="scss" scoped>
.flag-action-container {
  display: inline-block;
  font-size: 16px;
  color: rgb(144, 144, 144);

  .is-flagged {
    color: rgb(211, 22, 49);
    opacity: 1;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
</style>
