<script>
import baseModal from './base-modal.vue'
import featureFeedback from '../feature-feedback/feature-feedback'
import multiselect from 'vue-multiselect'
import moment from 'moment'

export default {
  name: 'i-94-modal',
  components: {
    baseModal,
    featureFeedback,
    multiselect
  },
  props: ['smartDocumentRequestItem', 'countriesList', 'classOfAdmissionsList'],
  emits: ['submit', 'disable-field'],
  data () {
    return {
      smartDocument: this.smartDocumentRequestItem.smart_document,
      countries: JSON.parse(this.countriesList),
      classOfAdmissions: JSON.parse(this.classOfAdmissionsList),
      disabledFields: []
    }
  },
  computed: {
    passportCountry: {
      get () {
        return this.countries.find(country => country.id.toString() === this.smartDocument.data.passport_or_travel_document_country_id.toString())
      },
      set (country) {
        this.smartDocument.data.passport_or_travel_document_country_id = country.id
      }
    },
    classOfAdmission: {
      get () {
        return this.classOfAdmissions.find(classOfAdmission => classOfAdmission.id.toString() === this.smartDocument.data.class_of_admission.toString())
      },
      set (classOfAdmission) {
        this.smartDocument.data.class_of_admission = classOfAdmission.id
      }
    },
    dateOfEntry: {
      get () {
        if (!this.smartDocument.data.date_of_entry) return ''

        const formatedDate = moment(this.smartDocument.data.date_of_entry).format('YYYY-MM-DD')
        return formatedDate
      },
      set (date) {
        if (date) {
          const formatedDate = moment(date).format('MM/DD/YYYY')
          this.smartDocument.data.date_of_entry = formatedDate
        } else {
          this.smartDocument.data.date_of_entry = ''
        }
      }
    },
    authorizedStayExpiry: {
      get () {
        if (!this.smartDocument.data.date_authorized_stay_expires) return ''

        const formatedDate = moment(this.smartDocument.data.date_authorized_stay_expires).format('YYYY-MM-DD')
        return formatedDate
      },
      set (date) {
        if (date) {
          const formatedDate = moment(date).format('MM/DD/YYYY')
          this.smartDocument.data.date_authorized_stay_expires = formatedDate
        } else {
          this.smartDocument.data.date_authorized_stay_expires = ''
        }
      }
    },
    dateOfBirth: {
      get () {
        if (!this.smartDocument.data.date_of_birth) return ''

        const formatedDate = moment(this.smartDocument.data.date_of_birth).format('YYYY-MM-DD')
        return formatedDate
      },
      set (date) {
        if (date) {
          const formatedDate = moment(date).format('MM/DD/YYYY')
          this.smartDocument.data.date_of_birth = formatedDate
        } else {
          this.smartDocument.data.date_of_birth = ''
        }
      }
    }
  },
  methods: {
    toggleDisableField (fieldName) {
      if (this.disabledFields.includes(fieldName)) {
        this.disabledFields = this.disabledFields.filter(field => field !== fieldName)
      } else {
        this.disabledFields.push(fieldName)
      }
    },
    getFeatureData () {
      return {
        smart_form_id: this.smartDocumentRequestItem.smart_form_id,
        smart_form_name: this.smartDocumentRequestItem.smart_form_name,
        smart_document_type: this.smartDocumentRequestItem.smart_document_type
      }
    },
    isDisabled (fieldName) {
      return this.disabledFields.includes(fieldName)
    }
  }
}
</script>

<template>
  <base-modal
    :modal-id="`smart-document-modal-${smartDocument.id}`"
    title="Review & Import"
  >
    <template #body>
      <div>
        <img
          :src="smartDocument.s3_signed_url"
          alt="I-94"
          width="500"
        >
      </div>

      <div class="form-group">
        <label for="i94_number">I-94 Number</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('i94_number'), 'fa-eye-slash': isDisabled('i94_number') }"
          @click="toggleDisableField('i94_number')"
        />
        <input
          id="i94_number"
          v-model="smartDocument.data.i94_number"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('i94_number')"
        >
      </div>

      <div class="form-group">
        <label for="date_of_entry">Date of Entry</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('date_of_entry'), 'fa-eye-slash': isDisabled('date_of_entry') }"
          @click="toggleDisableField('date_of_entry')"
        />
        <input
          id="date_of_entry"
          v-model="dateOfEntry"
          class="form-control"
          type="date"
          :disabled="disabledFields.includes('date_of_entry')"
        >
      </div>

      <div class="form-group">
        <label for="class_of_admission">Class of Admission</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('class_of_admission'), 'fa-eye-slash': isDisabled('class_of_admission') }"
          @click="toggleDisableField('class_of_admission')"
        />
        <multiselect
          v-model="classOfAdmission"
          name="class_of_admission"
          label="name"
          track-by="id"
          :options="classOfAdmissions"
          placeholder="Select class of admission"
          open-direction="bottom"
          :disabled="disabledFields.includes('class_of_admission')"
        />
      </div>

      <div class="form-group">
        <label for="date_authorized_stay_expires">Authorized Stay Expiry</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('date_authorized_stay_expires'), 'fa-eye-slash': isDisabled('date_authorized_stay_expires') }"
          @click="toggleDisableField('date_authorized_stay_expires')"
        />
        <input
          id="date_authorized_stay_expires"
          v-model="authorizedStayExpiry"
          class="form-control"
          type="date"
          :disabled="disabledFields.includes('date_authorized_stay_expires')"
        >
      </div>

      <div class="form-group">
        <label for="first_name_used">First Name Used</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('first_name_used'), 'fa-eye-slash': isDisabled('first_name_used') }"
          @click="toggleDisableField('first_name_used')"
        />
        <input
          id="first_name_used"
          v-model="smartDocument.data.first_name_used"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('first_name_used')"
        >
      </div>

      <div class="form-group">
        <label for="last_name_used">Last Name Used</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('last_name_used'), 'fa-eye-slash': isDisabled('last_name_used') }"
          @click="toggleDisableField('last_name_used')"
        />
        <input
          id="last_name_used"
          v-model="smartDocument.data.last_name_used"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('last_name_used')"
        >
      </div>

      <div class="form-group">
        <label for="date_of_birth">Date of Birth</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('date_of_birth'), 'fa-eye-slash': isDisabled('date_of_birth') }"
          @click="toggleDisableField('date_of_birth')"
        />
        <input
          id="date_of_birth"
          v-model="dateOfBirth"
          class="form-control"
          type="date"
          :disabled="disabledFields.includes('date_of_birth')"
        >
      </div>

      <div class="form-group">
        <label for="passport_number">Passport Number</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('passport_number'), 'fa-eye-slash': isDisabled('passport_number') }"
          @click="toggleDisableField('passport_number')"
        />
        <input
          id="passport_number"
          v-model="smartDocument.data.passport_number"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('passport_number')"
        >
      </div>

      <div class="form-group">
        <label for="passport_or_travel_document_country_id">Passport or Travel Document Country</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('passport_or_travel_document_country_id'), 'fa-eye-slash': isDisabled('passport_or_travel_document_country_id') }"
          @click="toggleDisableField('passport_or_travel_document_country_id')"
        />
        <multiselect
          v-model="passportCountry"
          name="passport_or_travel_document_country_id"
          label="name"
          track-by="id"
          :options="countries"
          placeholder="Select country"
          open-direction="bottom"
          :disabled="disabledFields.includes('passport_or_travel_document_country_id')"
        />
      </div>

      <feature-feedback
        feature-name="document_extraction_iq"
        :feature-data="getFeatureData()"
      />
    </template>

    <template #footer>
      <button
        class="btn btn-green full-width"
        @click="$emit('submit', smartDocument.data, disabledFields)"
      >
        CONFIRM & IMPORT
      </button>
    </template>
  </base-modal>
</template>
