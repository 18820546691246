<script>
import axios from 'axios'
import moment from 'moment'
import vueDropzone from 'vue2-dropzone/dist/vue2Dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import smartDocumentModal from './smart-document-modal.vue'
import flagAction from './flag-action.vue'
import hidingAction from './hiding-action.vue'

export default {
  name: 'contact-smart-document-request',
  components: {
    vueDropzone,
    smartDocumentModal,
    flagAction,
    hidingAction
  },
  props: ['smartDocumentRequestItem', 'countries', 'pusherKey', 'isAttorney', 'smartFormContactsList', 'classOfAdmissions'],
  data () {
    return {
      smartDocumentRequest: this.smartDocumentRequestItem,
      instructionsBoxEnabled: false,
      dropzoneOptions: {
        url: `/smart_document_requests/${this.smartDocumentRequestItem.id}/smart_documents`,
        createImageThumbnails: false,
        parallelUploads: 0,
        maxFiles: 1,
        maxFilesize: 100,
        acceptedFiles: 'image/*,application/pdf',
        autoProcessQueue: true,
        timeout: 99999999,
        dictDefaultMessage: "<img src='https://res.cloudinary.com/docketwise/image/upload/v1730240580/app-icons/sf-upload-icon_ip7aul.svg'/><div class='dw-sf-dropzone-internal-title'>Drag and drop or <span>choose file</span> to upload.</div><div class='dw-sf-dropzone-internal-formats'>JPG, JPEG, PNG or PDF</div>"
      },
      awss3: {
        signingURL: '/documents/s3_presigned_url'
      }
    }
  },
  computed: {
    isProcessing () {
      return this.smartDocumentRequest.status === 'processing'
    },
    completed () {
      return this.smartDocumentRequest.status === 'completed'
    },
    failed () {
      return this.smartDocumentRequest.status === 'failed'
    },
    createdAtAgo () {
      const createdAtParsed = Date.parse(this.smartDocumentRequest.smart_document.created_at)
      return moment(createdAtParsed).fromNow()
    }
  },
  methods: {
    initializePusher () {
      const pusher = new Pusher(this.pusherKey, { cluster: window.pusherCluster })
      const channelName = `document-extraction-${this.smartDocumentRequest.smart_document.id}-channel`
      const channel = pusher.subscribe(channelName)

      channel.bind('smart-document-processed', (notification) => {
        this.hadlePusherNotification(notification, pusher)
      })
    },
    async hadlePusherNotification (notification, pusher) {
      if (notification.success) {
        const smartDocumentUpdatedResponse = await axios.get(`/smart_document_requests/${this.smartDocumentRequest.id}/smart_documents/${this.smartDocumentRequest.smart_document.id}`)
        this.smartDocumentRequest.smart_document = smartDocumentUpdatedResponse.data
        this.smartDocumentRequest.status = 'completed'
        toastr.success('A smart document has been processed and is ready for use.')
      } else {
        this.smartDocumentRequest.status = 'failed'
        toastr.error('A smart document was unable to be processed')
      }
      pusher.disconnect()
    },
    modifyFormData (file, xhr, formData) {
      const authenticityTokenValue = $('#documents_authenticity_token').val()
      formData.append('authenticity_token', authenticityTokenValue)
      const renamedFileName = $(file.previewElement).find('.dz-filename span').html()
      formData.append('smart_document[title]', renamedFileName)
      formData.append('smart_document[size]', file.size)
      formData.append('smart_document[url]', file.s3ObjectLocation)
    },
    s3UploadError (errorMessage) {
      this.smartDocumentRequest.status = 'failed'
      toastr.error(errorMessage)
    },
    successCallback (_, response) {
      if (response.id) {
        this.smartDocumentRequest.status = 'processing'
        this.smartDocumentRequest.smart_document = response
        this.$refs.smartDocumentUploader.removeAllFiles()
        toastr.success(`The document ${this.smartDocumentRequest.smart_document.title} has been uploaded successfully. You will be notified once processing is complete, allowing you to edit and sync the extracted data.`)
        this.initializePusher()
      }
    },
    errorCallback (file, message, xhr) {
      this.smartDocumentRequest.status = 'failed'
      const errorMessage = `Sorry, something went wrong when uploading ${file.name}. Please try again later.`
      toastr.error(errorMessage)
    },
    async deleteSmartDocument () {
      if (!confirm('Are you sure you want to delete this document?')) return (null)
      try {
        const url = `/smart_document_requests/${this.smartDocumentRequest.id}/smart_documents/${this.smartDocumentRequest.smart_document.id}`
        await axios.delete(url)
        this.smartDocumentRequest.smart_document = null
        this.smartDocumentRequest.status = 'pending'
        toastr.success('Document successfully removed')
      } catch (e) {
        console.log(e)
        toastr.error('Something went wrong')
      }
    },
    reviewSmartDocument () {
      if (!this.smartDocumentRequest.smart_document.data) return (null)
      this.recordMixpanelEvent()
      $(`#smart-document-modal-${this.smartDocumentRequest.smart_document.id}`).modal('show')
    },
    recordMixpanelEvent () {
      const eventData = {
        name: 'document_extraction_iq_review',
        smart_form_id: this.smartDocumentRequest.smart_form_id,
        smart_form_name: this.smartDocumentRequest.smart_form_name,
        smart_document_type: this.smartDocumentRequest.smart_document_type
      }
      axios.post('/mixpanel/events', { event_related_data: eventData })
    },
    toggleInstructionsBox () {
      this.instructionsBoxEnabled = !this.instructionsBoxEnabled
    }
  }
}
</script>

<template>
  <div
    :id="`smart-document-request-${smartDocumentRequest.id}`"
    class="row question-and-comment-container"
  >
    <div class="col-sm-7">
      <div
        class="question-container"
        :class="{ 'hidden-question-container': smartDocumentRequest.hidden }"
      >
        <p>
          <b>Smart Document -</b>
          {{ smartDocumentRequest.smart_document_type_formatted }}
        </p>

        <div
          class="question-icons-div"
          style="position: absolute; right: 20px; top: 20px;"
        >
          <hiding-action
            v-if="isAttorney"
            :smart-document-request-record="smartDocumentRequest"
          />
          <flag-action
            :smart-document-request-record="smartDocumentRequest"
            :is-attorney="isAttorney"
          />
        </div>

        <div v-if="smartDocumentRequest.smart_document">
          <div class="dw-sf-uploaded-file-container">
            <div class="dw-sf-uploaded-file-left">
              <img src="https://res.cloudinary.com/docketwise/image/upload/v1730306786/app-icons/file-icon_umxlu7.svg">
            </div>
            <div class="dw-sf-uploaded-file-center">
              <div class="dw-sf-uploaded-file-name">
                {{ smartDocumentRequest.smart_document.title }}
              </div>
              <div class="dw-sf-uploaded-file-details">
                {{ `${smartDocumentRequest.smart_document.size}KB` }} <span>•</span> {{ createdAtAgo }}
              </div>
            </div>
            <div class="dw-sf-uploaded-file-right">
              <div
                class="dw-sf-uploaded-file-remove-icon"
                @click="deleteSmartDocument"
              >
                <img src="https://res.cloudinary.com/docketwise/image/upload/v1730307432/app-icons/close-x-icon_yso0mp.svg">
              </div>
              <div
                v-if="completed"
                class="dw-sf-uploaded-file-review-edit"
              >
                <a
                  href="#"
                  @click="reviewSmartDocument"
                >
                  Review & Edit
                </a>
              </div>
              <div
                v-if="isProcessing"
                class="dw-sf-smart-document-status-container"
              >
                <i class="fas fa-circle-notch fa-spin" />
                Processing...
              </div>
              <div
                v-if="failed"
                class="dw-sf-smart-document-status-container red"
              >
                <i class="fas fa-exclamation-circle" />
                Failed
              </div>
            </div>
          </div>
        </div>
        <vue-dropzone
          v-else
          id="smart-document-uploader"
          ref="smartDocumentUploader"
          class="dw-sf-dropzone"
          :options="dropzoneOptions"
          :awss3="awss3"
          @vdropzone-sending="modifyFormData"
          @vdropzone-s3-upload-error="s3UploadError"
          @vdropzone-success="successCallback"
          @vdropzone-error="errorCallback"
        />

        <div
          class="dw-sf-documents-dos-donts-container"
          @click="toggleInstructionsBox"
        >
          <div class="dw-sf-documents-dos-donts-left">
            <i class="fas fa-exclamation-circle"></i>
          </div>
          <div class="dw-sf-documents-dos-donts-center">
            <strong>Important:</strong> Read before uploading your document
          </div>
          <div class="dw-sf-documents-dos-donts-right">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1730467248/app-icons/caret-icon_f8w1vh.svg">
          </div>
        </div>
        <div
          v-if="instructionsBoxEnabled"
          class="dw-sf-documents-dos-donts-txt-container"
        >
          <div class="dw-sf-documents-dos-donts-txt-title dw-do-txt-color">
            Do:
          </div>
          <ul class="dw-sf-documents-dos-donts-txt-do-ul">
            <li>Ensure the image is high-resolution and in focus.</li>
            <li>Lay the document flat on a surface with no background objects.</li>
            <li>Capture the entire document, with no cut-off edges.</li>
            <li>Save the file as a PDF, PNG, or JPEG.</li>
          </ul>
          <div class="dw-sf-documents-dos-donts-txt-title dw-dont-txt-color">
            Don't:
          </div>
          <ul class="dw-sf-documents-dos-donts-txt-dont-ul">
            <li>Don't upload blurry, low-resolution, or angled images.</li>
            <li>Don't include multiple page PDF documents.</li>
            <li>Don't edit or alter the image in any way.</li>
            <li>Don't include any unnecessary background in the image.</li>
          </ul>
        </div>
      </div>
    </div>

    <smart-document-modal
      v-if="completed"
      :smart-document-request="smartDocumentRequest"
      :countries="countries"
      :smart-form-contacts-list="smartFormContactsList"
      :class-of-admissions="classOfAdmissions"
    />
  </div>
</template>
