<script>
import axios from 'axios'
import greenCardModal from './green-card-modal.vue'
import passportModal from './passport-modal.vue'
import employmentAuthorizationDocumentModal from './employment-authorization-document-modal.vue'
import i94Modal from './i-94-modal.vue'

export default {
  name: 'smart-document-modal',
  components: {
    greenCardModal,
    passportModal,
    employmentAuthorizationDocumentModal,
    i94Modal
  },
  props: ['smartDocumentRequest', 'countries', 'smartFormContactsList', 'classOfAdmissions'],
  data () {
    return {
      smartFormContacts: JSON.parse(this.smartFormContactsList)
    }
  },
  methods: {
    async handleSubmit (userData, disabledFields) {
      try {
        await axios.put(`/smart_document_requests/${this.smartDocumentRequest.id}/smart_documents/${this.smartDocumentRequest.smart_document.id}/import_data`, { user_modified_data: userData, disabled_fields: disabledFields })
        toastr.success('Contact successfully updated')
        $(`#smart-document-modal-${this.smartDocumentRequest.smart_document.id}`).modal('hide')
        const contactIndex = this.smartFormContacts.findIndex((contact) => {
          return contact.id === this.smartDocumentRequest.contact_id
        })
        let redirectUrl = new URL(window.location.toString())
        redirectUrl.searchParams.set('tab_name', `contact_smart_document_requests_${contactIndex}`)
        window.location.href = redirectUrl.toString()
      } catch (err) {
        console.log(err)
        toastr.error('Something went wrong')
      }
    }
  }
}
</script>

<template>
  <div>
    <passport-modal
      v-if="smartDocumentRequest.smart_document_type === 'passport'"
      :smart-document-request-item="smartDocumentRequest"
      :countries-list="countries"
      @submit="handleSubmit"
    />
    <green-card-modal
      v-if="smartDocumentRequest.smart_document_type === 'green_card'"
      :smart-document-request-item="smartDocumentRequest"
      :countries-list="countries"
      @submit="handleSubmit"
    />
    <employment-authorization-document-modal
      v-if="smartDocumentRequest.smart_document_type === 'employment_authorization_document'"
      :smart-document-request-item="smartDocumentRequest"
      :countries-list="countries"
      @submit="handleSubmit"
    />
    <i-94-modal
      v-if="smartDocumentRequest.smart_document_type === 'i_94'"
      :smart-document-request-item="smartDocumentRequest"
      :countries-list="countries"
      :class-of-admissions-list="classOfAdmissions"
      @submit="handleSubmit"
    />
  </div>
</template>

<style>
  .smart-document-icon-toggle-enable {
    margin-left: 5px;
    cursor: pointer;
  }
</style>
