<script>
import baseModal from './base-modal.vue'
import featureFeedback from '../feature-feedback/feature-feedback'
import multiselect from 'vue-multiselect'
import moment from 'moment'

export default {
  name: 'employment-authorization-document-modal',
  components: {
    baseModal,
    featureFeedback,
    multiselect
  },
  props: ['smartDocumentRequestItem', 'countriesList'],
  emits: ['submit', 'disable-field'],
  data () {
    return {
      smartDocument: this.smartDocumentRequestItem.smart_document,
      countries: JSON.parse(this.countriesList),
      genderOptions: ['Male', 'Female'],
      disabledFields: []
    }
  },
  computed: {
    countryOfBirth: {
      get () {
        return this.countries.find(country => country.id.toString() === this.smartDocument.data.country_of_birth_id.toString())
      },
      set (country) {
        this.smartDocument.data.country_of_birth_id = country.id
      }
    },
    dateOfBirth: {
      get () {
        if (!this.smartDocument.data.date_of_birth) return ''

        const formatedDate = moment(this.smartDocument.data.date_of_birth).format('YYYY-MM-DD')
        return formatedDate
      },
      set (date) {
        if (date) {
          const formatedDate = moment(date).format('MM/DD/YYYY')
          this.smartDocument.data.date_of_birth = formatedDate
        } else {
          this.smartDocument.data.date_of_birth = ''
        }
      }
    },
    eadExpiration: {
      get () {
        if (!this.smartDocument.data.employment_authorization_document_expiry) return ''

        const formatedDate = moment(this.smartDocument.data.employment_authorization_document_expiry).format('YYYY-MM-DD')
        return formatedDate
      },
      set (date) {
        if (date) {
          const formatedDate = moment(date).format('MM/DD/YYYY')
          this.smartDocument.data.employment_authorization_document_expiry = formatedDate
        } else {
          this.smartDocument.data.employment_authorization_document_expiry = ''
        }
      }
    }
  },
  methods: {
    toggleDisableField (fieldName) {
      if (this.disabledFields.includes(fieldName)) {
        this.disabledFields = this.disabledFields.filter(field => field !== fieldName)
      } else {
        this.disabledFields.push(fieldName)
      }
    },
    getFeatureData () {
      return {
        smart_form_id: this.smartDocumentRequestItem.smart_form_id,
        smart_form_name: this.smartDocumentRequestItem.smart_form_name,
        smart_document_type: this.smartDocumentRequestItem.smart_document_type
      }
    },
    isDisabled (fieldName) {
      return this.disabledFields.includes(fieldName)
    }
  }
}
</script>

<template>
  <base-modal
    :modal-id="`smart-document-modal-${smartDocument.id}`"
    title="Review & Import"
  >
    <template #body>
      <div>
        <img
          :src="smartDocument.s3_signed_url"
          alt="Employment Authorization Document"
          width="500"
        >
      </div>

      <div class="form-group">
        <label for="first_name">First Name</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('first_name'), 'fa-eye-slash': isDisabled('first_name') }"
          @click="toggleDisableField('first_name')"
        />
        <input
          id="first_name"
          v-model="smartDocument.data.first_name"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('first_name')"
        >
      </div>

      <div class="form-group">
        <label for="last_name">Last Name</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('last_name'), 'fa-eye-slash': isDisabled('last_name') }"
          @click="toggleDisableField('last_name')"
        />
        <input
          id="last_name"
          v-model="smartDocument.data.last_name"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('last_name')"
        >
      </div>

      <div class="form-group">
        <label for="alien_registration_number">Alien Registration Number</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('alien_registration_number'), 'fa-eye-slash': isDisabled('alien_registration_number') }"
          @click="toggleDisableField('alien_registration_number')"
        />
        <input
          id="alien_registration_number"
          v-model="smartDocument.data.alien_registration_number"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('alien_registration_number')"
        >
      </div>

      <div class="form-group">
        <label for="gender">Gender</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('gender'), 'fa-eye-slash': isDisabled('gender') }"
          @click="toggleDisableField('gender')"
        />
        <select
          id="gender"
          v-model="smartDocument.data.gender"
          class="form-control"
          :disabled="disabledFields.includes('gender')"
        >
          <option
            value=""
          >
            Select Gender
          </option>
          <option
            v-for="option in genderOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="date_of_birth">Date of Birth</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('date_of_birth'), 'fa-eye-slash': isDisabled('date_of_birth') }"
          @click="toggleDisableField('date_of_birth')"
        />
        <input
          id="date_of_birth"
          v-model.lazy="dateOfBirth"
          class="form-control"
          type="date"
          :disabled="disabledFields.includes('date_of_birth')"
        >
      </div>

      <div class="form-group">
        <label for="country_of_birth_id">Country of Birth</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('country_of_birth_id'), 'fa-eye-slash': isDisabled('country_of_birth_id') }"
          @click="toggleDisableField('country_of_birth_id')"
        />
        <multiselect
          v-model="countryOfBirth"
          name="country_of_birth_id"
          label="name"
          track-by="id"
          :options="countries"
          placeholder="Select country"
          open-direction="bottom"
          :disabled="disabledFields.includes('country_of_birth_id')"
        />
      </div>

      <div class="form-group">
        <label for="employment_authorization_document_expiry">EAD Expiration</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('employment_authorization_document_expiry'), 'fa-eye-slash': isDisabled('employment_authorization_document_expiry') }"
          @click="toggleDisableField('employment_authorization_document_expiry')"
        />
        <input
          id="employment_authorization_document_expiry"
          v-model.lazy="eadExpiration"
          class="form-control"
          type="date"
          :disabled="disabledFields.includes('employment_authorization_document_expiry')"
        >
      </div>

      <div class="form-group">
        <label for="employment_authorization_document_number">EAD Number</label>
        <i
          class="smart-document-icon-toggle-enable fa"
          :class="{ 'fa-eye primary': !isDisabled('employment_authorization_document_number'), 'fa-eye-slash': isDisabled('employment_authorization_document_number') }"
          @click="toggleDisableField('employment_authorization_document_number')"
        />
        <input
          id="employment_authorization_document_number"
          v-model="smartDocument.data.employment_authorization_document_number"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('employment_authorization_document_number')"
        >
      </div>

      <feature-feedback
        feature-name="document_extraction_iq"
        :feature-data="getFeatureData()"
      />
    </template>

    <template #footer>
      <button
        class="btn btn-green full-width"
        @click="$emit('submit', smartDocument.data, disabledFields)"
      >
        CONFIRM & IMPORT
      </button>
    </template>
  </base-modal>
</template>
