<script>
import contactSmartDocumentRequest from './contact-smart-document-request'

export default {
  name: 'contact-smart-document-requests',
  components: {
    contactSmartDocumentRequest
  },
  props: ['smartDocumentRequestsList', 'countriesList', 'pusherKey', 'isAttorney', 'smartFormContactsList', 'classOfAdmissions'],
  data () {
    return {
      smartDocumentRequests: JSON.parse(this.smartDocumentRequestsList)
    }
  }
}
</script>

<template>
  <div>
    <contact-smart-document-request
      v-for="(smartDocumentRequest, index) in smartDocumentRequests"
      :key="index"
      :smart-document-request-item="smartDocumentRequest"
      :countries="countriesList"
      :pusher-key="pusherKey"
      :is-attorney="isAttorney"
      :smart-form-contacts-list="smartFormContactsList"
      :class-of-admissions="classOfAdmissions"
    />
  </div>
</template>
